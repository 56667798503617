/* eslint-disable import/no-anonymous-default-export */
import Profile from "../image/blog/thumb1.jpg";
export default [
  {
    id: "1",
    thumb2: [Profile],
    cmt_main:"Expert knowledge of Agile theory, Lean Software Development",
    rply_main:"The Jr. Agile Coach is critical to the success of Entrata’s Research and Development",
       
  },
  {
    id: "2",
    thumb2: [Profile],
    cmt_main:"to the success of Entrata’s Research and Development",
    rply_main:"The Jr. Agile Coach is critical to the success of Entrata’s Research and Development",
       
  },
  {
    id: "3",
    thumb2: [Profile],
    cmt_main:" is critical to the success of En",
    rply_main:"The Jr. Agile Coach is critical to the success of Entrata’s Research and Development",
       
  },
  {
    id: "4",
    thumb2: [Profile],
    cmt_main:" Lean Software Development",
    rply_main:"The Jr. Agile Coach is nd Development",
       
  },
  {
    id: "5",
    thumb2: [Profile],
    cmt_main:"Expert knowledge of Agile theory, Lean Software Development",
    rply_main:"The Jr. Agile Coach is critical to the success of Entrata’s Research and Development",
       
  },
];
