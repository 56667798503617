import React from 'react'
import Logincom from "../component/Login";

function Login() {
  return (
   <>
   <Logincom/>
   </>
  )
}

export default Login