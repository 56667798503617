import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./css/style.css";
// import Home11 from "./pages/home";
// import Footer from "./component/footer";
// import Newheader from "../src/component/new_header";
// import Aboutteam from "../src/pages/about_team";
// import Lifevertipride from "../src/pages/life_vertipride";
// import Career from "../src/pages/career";
// import Contact from "../src/pages/contact";
// import Androiddev from "../src/pages/Android_dev";
// import Iosdev from "../src/pages/ios_Dev";
// import Flutter from "../src/pages/flutter";
import Webdev from "../src/pages/web_dev";
import Aidev from "../src/pages/al_dev";
import Uiux from "../src/pages/ui_ux";
import Quality from "../src/pages/quality";
import Php from "../src/pages/php";
import Ban from "./menu/Bar";
import Foo from "./component/single_hiring";
import Products from "./component/Products";
import ProductDetail from "./component/productdetails";
import Login from "./pages/Login";
import Gallery from "../src/pages/gallery";
// import HospitalERP from "./pages/hospital_ERP";
import Working from "./pages/working"
import { useState, useEffect } from 'react';
import Preloader from './component/Preloader';

import Blogs from "./blog/blogs";
import Blogdetails from "./blog/blogdetails";

// import Ui_Ux_Portfolio from "./pages/Ui_Ux_Portfolio";
import WebAndroid from "./pages/web_android";



const Aboutus = React.lazy(() => import("./pages/about_us"));
const Home = React.lazy(() => import("./pages/home"));
const Aboutteam = React.lazy(() => import("./pages/about_team"));
const Career = React.lazy(() => import("./pages/career"));
const LifeVertipride = React.lazy(() => import("./pages/lifeVertipride"));
const Contact = React.lazy(() => import("./pages/contact"));
const Androiddev = React.lazy(() => import("./pages/Android_dev"));
const Iosdev = React.lazy(() => import("./pages/ios_Dev"));
const UiUxPortfolio = React.lazy(() => import("./pages/Ui_Ux_portfolio"));
const HospitalErp = React.lazy(() => import("./pages/hospital_ERP"));
const Flutter = React.lazy(() => import("./pages/flutter"));


<link
  rel="stylesheet"
  href="https://fonts.googleapis.com/icon?family=Material+Icons"
/>;

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate an async operation, e.g., fetching data
    setTimeout(() => {
      setIsLoading(false);
    }, 3000); // Replace this with your actual data fetching logic
  }, []);


  if (isLoading) {
    return <Preloader isLoading={isLoading} />
  }
  return (
    <>

      {/* <Preloader isLoading={isLoading} delay={3000} /> */}
      {/* {isLoading ? (
          <Preloader />
        ) : ( */}
      <BrowserRouter>
        <Suspense fallback={<Preloader/>}>

          <Routes>
            {/* <Route path="*" element={<Newheader />}> */}
            <Route index element={<Home />} />
            <Route exect path="aboutus" element={<Aboutus />} />
            <Route exect path="aboutteam" element={<Aboutteam />} />
            <Route path="career" element={<Career />} />
            <Route path="lifeVertipride" element={<LifeVertipride />} />

            <Route path="android_dev" element={<Androiddev />} />
            <Route path="contact" element={<Contact />} />
            <Route path="ios_dev" element={<Iosdev />} />
            <Route path="flutter" element={<Flutter />} />
            <Route path="web_dev" element={<Webdev />} />
            <Route path="ai_dev" element={<Aidev />} />
            <Route path="ui_us" element={<Uiux />} />
            <Route path="quality" element={<Quality />} />
            <Route path="php" element={<Php />} />
            <Route path="ban" element={<Ban />} />
            <Route path="foo" element={<Foo />} />
            <Route path="gallery" element={<Gallery />} />
            <Route path="hospital_erp" element={<HospitalErp />} />
            <Route path="working" element={<Working />} />
            <Route path="Login" element={<Login />} />


            {/* hiring page section */}
            <Route path="products" element={<Products />} />
            <Route path="products/:productId" element={<ProductDetail />} />
            {/* blog pages dynamic start */}
            <Route path="blog" element={<Blogs />} />
            <Route path="blog/:blogId" element={<Blogdetails />} />
            {/* portfolio header */}
            <Route path="Ui_Ux_portfolio" element={<UiUxPortfolio />} />
            <Route path="web_android" element={<WebAndroid />} />
            {/* </Route> */}
          </Routes>


        </Suspense>
        {/* <Footer /> */}

      </BrowserRouter>
      {/* )} */}





    </>
  );
}

export default App;
