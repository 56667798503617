/* eslint-disable import/no-anonymous-default-export */
import react from "../image/blog/blog-inner.jpg";
import blog2 from "../image/images.jpg";
import banner_img from "../image/blog/3.png"
export default [
  {
    id: "1",
    title: "adipiscing elit",
    description: "bibendum ornare. Vestibulum nec mi sed metus sodales",
    date:"18 June",
    single_blog_heading:"consectetur adipiscing elit. Fusce bibendum bibendum ornare.",
    longdesc_1: "loreLorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce bibendum bibendum ornare. Vestibulum nec mi sed metus sodales sagittis. Donec est nibh, aliquet a iaculis ut, condimentum non nulla. Sed vel urna mollis, suscipit dolor id, non pellentesque arcu mi sed arcu. Mauris fringilla faucibus risus eget ullamcorper. In laoreet scelerisque purus vitae cursus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce bibendum bibendum ornare. Vestibulum nec mi sed metus sodales sagittis. Donec est nibh, aliquet a iaculis ut, condimentum non nulla. Sed vel urna mollis, suscipit dolor id, dapibus nisi. Morbi feugiat pharetra ipsum, vel tempor sem dapibus a. Nam pretium pulvinar eros. Donec efficitur, sapien non gravida facilisis, libero mi porttitor ante, in ullamcorper metus lacus eget justo. Vivamus congue, est aliquet viverra molestie, metus est dictum purus, non pellentesque arcu mi sed arcu. Mauris fringilla faucibus risus eget ullamcorper. In laoreet scelerisque purus vitae cursus metus est dictum purus, non pellentesque arcu mi sed arcu. Mauris fringilla Nam pretium pulvinar eros. Donec efficitur nec mi sed metus sodales sagittis. Donec est nibh, aliquet a iaculis ut.",
    longdesc_2: "usce bibendum bibendum ornare. Vestibulum nec mi sed metus sodales sagittis. Donec est nibh, aliquet a iaculis ut, condimentum non nulla. Sed vel urna mollis, suscipit dolor id, non pellentesque arcu mi sed arcu. Mauris fringilla faucibus risus eget ullamcorper. In laoreet scelerisque purus vitae cursus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce bibendum bibendum ornare. Vestibulum nec mi sed metus sodales sagittis. Donec est nibh, aliquet a iaculis ut, condimentum non nulla. Sed vel urna mollis, suscipit dolor id, dapibus nisi. Morbi feugiat pharetra ipsum, vel tempor sem dapibus a. Nam pretium pulvinar eros. Donec efficitur, sapien non gravida facilisis, libero mi porttitor ante, in ullamcorper metus lacus eget justo. Vivamus congue, est aliquet viverra molestie, metus est dictum purus, non pellentesque arcu mi sed arcu. Mauris fringilla faucibus risus eget ullamcorper. In laoreet scelerisque purus vitae cursus metus est dictum purus, non pellentesque arcu mi sed arcu. Mauris fringilla Nam pretium pulvinar eros. Donec efficitur nec mi sed metus sodales sagittis. Donec est nibh, aliquet a iaculis ut.",
    type: "Work From Home",
    single_page_banner: [react],
    blog_banner_img: [banner_img],
    
  },
  {
    id: "2",
    title: "adipiscing elit",
    description: "bibendum ornare. Vestibulum nec mi sed metus sodales",
    date:"18 June",
    single_blog_heading:"consectetur adipiscing elit. Fusce bibendum bibendum ornare.",
    longdesc_1: "loreLorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce bibendum bibendum ornare. Vestibulum nec mi sed metus sodales sagittis. Donec est nibh, aliquet a iaculis ut, condimentum non nulla. Sed vel urna mollis, suscipit dolor id, non pellentesque arcu mi sed arcu. Mauris fringilla faucibus risus eget ullamcorper. In laoreet scelerisque purus vitae cursus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce bibendum bibendum ornare. Vestibulum nec mi sed metus sodales sagittis. Donec est nibh, aliquet a iaculis ut, condimentum non nulla. Sed vel urna mollis, suscipit dolor id, dapibus nisi. Morbi feugiat pharetra ipsum, vel tempor sem dapibus a. Nam pretium pulvinar eros. Donec efficitur, sapien non gravida facilisis, libero mi porttitor ante, in ullamcorper metus lacus eget justo. Vivamus congue, est aliquet viverra molestie, metus est dictum purus, non pellentesque arcu mi sed arcu. Mauris fringilla faucibus risus eget ullamcorper. In laoreet scelerisque purus vitae cursus metus est dictum purus, non pellentesque arcu mi sed arcu. Mauris fringilla Nam pretium pulvinar eros. Donec efficitur nec mi sed metus sodales sagittis. Donec est nibh, aliquet a iaculis ut.",
    longdesc_2: "usce bibendum bibendum ornare. Vestibulum nec mi sed metus sodales sagittis. Donec est nibh, aliquet a iaculis ut, condimentum non nulla. Sed vel urna mollis, suscipit dolor id, non pellentesque arcu mi sed arcu. Mauris fringilla faucibus risus eget ullamcorper. In laoreet scelerisque purus vitae cursus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce bibendum bibendum ornare. Vestibulum nec mi sed metus sodales sagittis. Donec est nibh, aliquet a iaculis ut, condimentum non nulla. Sed vel urna mollis, suscipit dolor id, dapibus nisi. Morbi feugiat pharetra ipsum, vel tempor sem dapibus a. Nam pretium pulvinar eros. Donec efficitur, sapien non gravida facilisis, libero mi porttitor ante, in ullamcorper metus lacus eget justo. Vivamus congue, est aliquet viverra molestie, metus est dictum purus, non pellentesque arcu mi sed arcu. Mauris fringilla faucibus risus eget ullamcorper. In laoreet scelerisque purus vitae cursus metus est dictum purus, non pellentesque arcu mi sed arcu. Mauris fringilla Nam pretium pulvinar eros. Donec efficitur nec mi sed metus sodales sagittis. Donec est nibh, aliquet a iaculis ut.",
    type: "Work From Home",
    single_page_banner: [react],
    blog_banner_img: [banner_img],
    
  },
  {
    id: "3",
    title: "adipiscing elit",
    description: "bibendum ornare. Vestibulum nec mi sed metus sodales",
    date:"18 June",
    single_blog_heading:"consectetur adipiscing elit. Fusce bibendum bibendum ornare.",
    longdesc_1: "loreLorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce bibendum bibendum ornare. Vestibulum nec mi sed metus sodales sagittis. Donec est nibh, aliquet a iaculis ut, condimentum non nulla. Sed vel urna mollis, suscipit dolor id, non pellentesque arcu mi sed arcu. Mauris fringilla faucibus risus eget ullamcorper. In laoreet scelerisque purus vitae cursus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce bibendum bibendum ornare. Vestibulum nec mi sed metus sodales sagittis. Donec est nibh, aliquet a iaculis ut, condimentum non nulla. Sed vel urna mollis, suscipit dolor id, dapibus nisi. Morbi feugiat pharetra ipsum, vel tempor sem dapibus a. Nam pretium pulvinar eros. Donec efficitur, sapien non gravida facilisis, libero mi porttitor ante, in ullamcorper metus lacus eget justo. Vivamus congue, est aliquet viverra molestie, metus est dictum purus, non pellentesque arcu mi sed arcu. Mauris fringilla faucibus risus eget ullamcorper. In laoreet scelerisque purus vitae cursus metus est dictum purus, non pellentesque arcu mi sed arcu. Mauris fringilla Nam pretium pulvinar eros. Donec efficitur nec mi sed metus sodales sagittis. Donec est nibh, aliquet a iaculis ut.",
    longdesc_2: "usce bibendum bibendum ornare. Vestibulum nec mi sed metus sodales sagittis. Donec est nibh, aliquet a iaculis ut, condimentum non nulla. Sed vel urna mollis, suscipit dolor id, non pellentesque arcu mi sed arcu. Mauris fringilla faucibus risus eget ullamcorper. In laoreet scelerisque purus vitae cursus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce bibendum bibendum ornare. Vestibulum nec mi sed metus sodales sagittis. Donec est nibh, aliquet a iaculis ut, condimentum non nulla. Sed vel urna mollis, suscipit dolor id, dapibus nisi. Morbi feugiat pharetra ipsum, vel tempor sem dapibus a. Nam pretium pulvinar eros. Donec efficitur, sapien non gravida facilisis, libero mi porttitor ante, in ullamcorper metus lacus eget justo. Vivamus congue, est aliquet viverra molestie, metus est dictum purus, non pellentesque arcu mi sed arcu. Mauris fringilla faucibus risus eget ullamcorper. In laoreet scelerisque purus vitae cursus metus est dictum purus, non pellentesque arcu mi sed arcu. Mauris fringilla Nam pretium pulvinar eros. Donec efficitur nec mi sed metus sodales sagittis. Donec est nibh, aliquet a iaculis ut.",
    type: "Work From Home",
    single_page_banner: [react],
    blog_banner_img: [banner_img],
    
  },
  {
    id: "4",
    title: "adipiscing elit",
    description: "bibendum ornare. Vestibulum nec mi sed metus sodales",
    date:"18 June",
    single_blog_heading:"consectetur adipiscing elit. Fusce bibendum bibendum ornare.",
    longdesc_1: "loreLorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce bibendum bibendum ornare. Vestibulum nec mi sed metus sodales sagittis. Donec est nibh, aliquet a iaculis ut, condimentum non nulla. Sed vel urna mollis, suscipit dolor id, non pellentesque arcu mi sed arcu. Mauris fringilla faucibus risus eget ullamcorper. In laoreet scelerisque purus vitae cursus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce bibendum bibendum ornare. Vestibulum nec mi sed metus sodales sagittis. Donec est nibh, aliquet a iaculis ut, condimentum non nulla. Sed vel urna mollis, suscipit dolor id, dapibus nisi. Morbi feugiat pharetra ipsum, vel tempor sem dapibus a. Nam pretium pulvinar eros. Donec efficitur, sapien non gravida facilisis, libero mi porttitor ante, in ullamcorper metus lacus eget justo. Vivamus congue, est aliquet viverra molestie, metus est dictum purus, non pellentesque arcu mi sed arcu. Mauris fringilla faucibus risus eget ullamcorper. In laoreet scelerisque purus vitae cursus metus est dictum purus, non pellentesque arcu mi sed arcu. Mauris fringilla Nam pretium pulvinar eros. Donec efficitur nec mi sed metus sodales sagittis. Donec est nibh, aliquet a iaculis ut.",
    longdesc_2: "usce bibendum bibendum ornare. Vestibulum nec mi sed metus sodales sagittis. Donec est nibh, aliquet a iaculis ut, condimentum non nulla. Sed vel urna mollis, suscipit dolor id, non pellentesque arcu mi sed arcu. Mauris fringilla faucibus risus eget ullamcorper. In laoreet scelerisque purus vitae cursus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce bibendum bibendum ornare. Vestibulum nec mi sed metus sodales sagittis. Donec est nibh, aliquet a iaculis ut, condimentum non nulla. Sed vel urna mollis, suscipit dolor id, dapibus nisi. Morbi feugiat pharetra ipsum, vel tempor sem dapibus a. Nam pretium pulvinar eros. Donec efficitur, sapien non gravida facilisis, libero mi porttitor ante, in ullamcorper metus lacus eget justo. Vivamus congue, est aliquet viverra molestie, metus est dictum purus, non pellentesque arcu mi sed arcu. Mauris fringilla faucibus risus eget ullamcorper. In laoreet scelerisque purus vitae cursus metus est dictum purus, non pellentesque arcu mi sed arcu. Mauris fringilla Nam pretium pulvinar eros. Donec efficitur nec mi sed metus sodales sagittis. Donec est nibh, aliquet a iaculis ut.",
    type: "Work From Home",
    single_page_banner: [react],
    blog_banner_img: [banner_img],
    
  },
  {
    id: "4",
    title: "adipiscing elit",
    description: "bibendum ornare. Vestibulum nec mi sed metus sodales",
    date:"18 June",
    single_blog_heading:"consectetur adipiscing elit. Fusce bibendum bibendum ornare.",
    longdesc_1: "loreLorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce bibendum bibendum ornare. Vestibulum nec mi sed metus sodales sagittis. Donec est nibh, aliquet a iaculis ut, condimentum non nulla. Sed vel urna mollis, suscipit dolor id, non pellentesque arcu mi sed arcu. Mauris fringilla faucibus risus eget ullamcorper. In laoreet scelerisque purus vitae cursus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce bibendum bibendum ornare. Vestibulum nec mi sed metus sodales sagittis. Donec est nibh, aliquet a iaculis ut, condimentum non nulla. Sed vel urna mollis, suscipit dolor id, dapibus nisi. Morbi feugiat pharetra ipsum, vel tempor sem dapibus a. Nam pretium pulvinar eros. Donec efficitur, sapien non gravida facilisis, libero mi porttitor ante, in ullamcorper metus lacus eget justo. Vivamus congue, est aliquet viverra molestie, metus est dictum purus, non pellentesque arcu mi sed arcu. Mauris fringilla faucibus risus eget ullamcorper. In laoreet scelerisque purus vitae cursus metus est dictum purus, non pellentesque arcu mi sed arcu. Mauris fringilla Nam pretium pulvinar eros. Donec efficitur nec mi sed metus sodales sagittis. Donec est nibh, aliquet a iaculis ut.",
    longdesc_2: "usce bibendum bibendum ornare. Vestibulum nec mi sed metus sodales sagittis. Donec est nibh, aliquet a iaculis ut, condimentum non nulla. Sed vel urna mollis, suscipit dolor id, non pellentesque arcu mi sed arcu. Mauris fringilla faucibus risus eget ullamcorper. In laoreet scelerisque purus vitae cursus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce bibendum bibendum ornare. Vestibulum nec mi sed metus sodales sagittis. Donec est nibh, aliquet a iaculis ut, condimentum non nulla. Sed vel urna mollis, suscipit dolor id, dapibus nisi. Morbi feugiat pharetra ipsum, vel tempor sem dapibus a. Nam pretium pulvinar eros. Donec efficitur, sapien non gravida facilisis, libero mi porttitor ante, in ullamcorper metus lacus eget justo. Vivamus congue, est aliquet viverra molestie, metus est dictum purus, non pellentesque arcu mi sed arcu. Mauris fringilla faucibus risus eget ullamcorper. In laoreet scelerisque purus vitae cursus metus est dictum purus, non pellentesque arcu mi sed arcu. Mauris fringilla Nam pretium pulvinar eros. Donec efficitur nec mi sed metus sodales sagittis. Donec est nibh, aliquet a iaculis ut.",
    type: "Work From Home",
    single_page_banner: [react],
    blog_banner_img: [banner_img],
    
  },
  
];
